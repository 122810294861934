@import url('https://fonts.googleapis.com/css2?family=Eczar:wght@400;600;700&display=swap');

body{
  font-family: 'Eczar', serif;
  background: #EDE0C8;
  box-shadow: 0 0 200px #b58c4a inset;
  min-height: 100vh;
  box-sizing: border-box;
}
*{
  box-sizing: border-box;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.embryo{
  margin-bottom: 20px;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
header{
  position: relative;
}
.App-header {
  background-color: #000;
  min-height: 10vh;
  padding: 40px 0px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.App-header p{
  font-size: 16px;
  margin-bottom: 0;
  vertical-align: middle;
}
.App-header p a{
  display: inline-flex;
  font-weight: bold;
  color: #FFF;
  text-decoration: none;
  vertical-align: middle;
}
.App-header p a img{
  margin-right: 8px;
  display: inline-block;
  border-radius: 4px;
  vertical-align: middle;
}

.fisrt-instructions{
  font-size: 18px;
  margin-bottom: 20px;
}
.wallet-address{
  background: #500659;
  padding: 1px 0px;
}
.wallet-address p{
  display: inline-flex;
  border: 2px solid #27032b;
  padding: 16px 24px 16px 12px;
  border-radius: 6px;
  text-align: left;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  color: #FFF;
  background: #3e0346;
  box-shadow: 0 0 25px #00000060 inset;
}

.container{
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 48px 5vw;
  margin: 0 auto;
}
.container .row{
  display: flex;
  justify-content: space-between;
  gap: 24px;
}
.container .row.row-center{
  align-items: center;
}
.container .row.row-center h2{
  font-size: 36px;
  font-weight: bold;
  position: relative;
  width: calc(50% - 12px);
  text-align: left;
  background: linear-gradient(180deg, #74245b 0%, #1a0513 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.container .row.row-center h2:after{
  content: '';
  height: 1px;
  width: 50%;
  background: linear-gradient(90deg, rgba(255, 245, 156, 0.00) 0%, #681f51 100%);
  display: block;
  position: absolute;
  right: 0;
  top: 50%;
}
.container .row .card{
  width: 50%;
  text-align: center;
  border: 2px solid #AE8443;
  border-radius: 6px;
  padding: 24px 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow: 0 0 40px #b58c4a60 inset;
  font-size: 24px;
}
.container .row .card b{
  display: block;
  font-size: 24px;
  color: #481839;
}
.container .row .card div.date{
  font-size: 16px;
  padding: 0;
  margin:0;
}
.container .row .card.card-loading{
  width: 100% !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 24px;
}

.App-link {
  color: #61dafb;
}
.bevel-button{
  background: #491663;
  font-size: 24px;
  padding: 20px 32px 20px;
  font-weight: 700;
  border-radius: 6px;
  width: calc(50% - 12px);
  border: 2px solid #ae8342;
  line-height: 20px;
  position: relative;
  transition: all 0.2s ease-out;
  display: inline-block;
  font-family: 'Eczar', serif;
  color: #FFF;
  cursor: pointer;
}
.bevel-button:hover{
  background: #39104e;
}
.bevel-button:active{
  transform: scale(0.98);
}
.bevel-button:after{
  position: absolute;
  content: '';
  display: block;
  height: 5px;
  width: auto;
  top: 3px;
  left: 3px;
  right: 3px;
  background: linear-gradient( #80439e 0% , #80439e00 100%);
  border-radius: 3px;
}
.bevel-button:before{
  position: absolute;
  content: '';
  display: block;
  height: 5px;
  width: auto;
  bottom: 3px;
  left: 3px;
  right: 3px;
  background: linear-gradient( #80439e00 0% , #1f0c29 100%);
  border-radius: 3px;
}
.google-calendar{
  background: #f7f7f7;
  border-radius: 6px;
  border: 2px solid #777;
  font-family: 'Eczar', serif;
  padding: 6px 10px 6px 40px;
  color:#666;
  cursor: pointer;
  font-weight: bold;
  transition: all 0.3s linear;
  position: relative;
  margin-top: 12px;
}
.google-calendar:after{
  content: '';
  display: block;
  height: 20px;
  width: 20px;
  background: url(../public/calendar.png) center center no-repeat;
  background-size: contain;
  position: absolute;
  border-radius: 4px;
  left: 8px;
  top: 8px;
}
.google-calendar:hover{
  background: #FFF;
  color: #000;
  border: 2px solid #4285F4;
}
.warning-message{
  color: #c56d1b;
  border: 2px solid #d57e2d;
  background: rgba(255,255,255,0.2);
  border-radius: 6px;
  width: calc(50% - 12px);
  padding: 8px 16px;

}
.warning-message:before{
  content: '';
  width: 16px;
  height: 16px;
  display: inline-block;
  vertical-align: middle;
  background: url(../public/info.svg) center center no-repeat;
  margin-right: 12px;
  margin-top: -2px;
}


.success-message{
  color: #1f6231;
  border: 2px solid #1f6231;
  background: rgba(255,255,255,0.2);
  border-radius: 6px;
  width: calc(50% - 12px);
  padding: 8px 16px;

}
.success-message:before{
  content: '';
  width: 16px;
  height: 16px;
  display: inline-block;
  vertical-align: middle;
  background: url(../public/success.svg) center center no-repeat;
  margin-right: 12px;
  margin-top: -2px;
}


.solutions{
  background: #101010;
  border-left: 1px solid #222;
  color:#FFF;
  padding: 40px;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 33%;
  min-width: 600px;
  box-shadow: 0 0 80px #000000;
  overflow: auto;
}
.solutions li a{
  color: #FFF;
}
.solutions h3{
  font-size: 48px;
  color: #FFF;
  padding: 0;
  margin: 0;
}
header button,
.solutions button{
  padding: 0;
  border: none;
  background: none;
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
  vertical-align: middle;
  color: #FEA400;
  display: inline-flex;
  align-items: center;
  gap: 16px;
  font-family: 'Eczar', serif;
  font-size: 16px;
  font-weight: bold;
}
header button img,
.solutions button img{
  transition: all 0.3s linear;
}
header button:hover img,
.solutions button:hover img{
  transform: scale(0.95);
}
.solutions ol{
  text-align: left;
  color:#EEE;
}
.solutions ol li{
  margin-bottom: 20px;
}
.bridge-alert{
  color: #cf7929;
  border: 2px solid #d57e2d;
  padding: 30px;
  border-radius: 6px;
  margin: 0 0 0 30px;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
